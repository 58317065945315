import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import Cookies from "js-cookie";
const routeuser = localStorage.getItem("role");
console.log(routeuser, 'routeuser')

const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  PermContactCalendarIcon : PermContactCalendarIcon,
  LocalMallIcon : LocalMallIcon,
  AddBoxIcon : AddBoxIcon,
  NoteAddIcon : NoteAddIcon,
  MarkUnreadChatAltIcon : MarkUnreadChatAltIcon,
  PersonAddAltTwoToneIcon :PersonAddAltTwoToneIcon,
};

const adminRoutes = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: icons['HomeOutlinedIcon'],
    url: '/dashboard/default'
  },
  {
    id: 'product-list',
    title: 'Available Stock',
    type: 'item',
    url: '/product-list',
    icon: icons['LocalMallIcon']   
  },
  // {
  //   id: 'create-order',
  //   title: 'Create Order',
  //   type: 'item',
  //   url: '/create-order',
  //   icon: icons['AddBoxIcon']
  // },
  {
    id: 'order-list',
    title: 'Order List',
    type: 'item',
    url: '/order-list',
    icon: icons['ChromeReaderModeOutlinedIcon']
  },

  // {
  //   id: 'closing-stock',
  //   title: 'Closing Stock',
  //   type: 'item',
  //   url: '/closing-stock',
  //   icon: icons['NoteAddIcon']
  // },
  {
    id: 'add-retailer',
    title: 'Add Retailer',
    type: 'item',
    url: '/add-retailer',
    icon: icons['PersonAddAltTwoToneIcon']
  },
  {
    id: 'Retailer',
    title: 'Retailer List',
    type: 'item',
    url: '/retailer',
    icon: icons['PermContactCalendarIcon']
  },
  {
    id: 'send-Notification',
    title: 'Notification for Retailer',
    type: 'item',
    url: '/send-Notification',
    icon: icons['MarkUnreadChatAltIcon']
  },
];

const retailerRoutes = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: icons['HomeOutlinedIcon'],
    url: '/dashboard/default'
  },
  {
    id: 'product-list',
    title: 'Available Stock',
    type: 'item',
    url: '/product-list',
    icon: icons['LocalMallIcon']   
  },
  // {
  //   id: 'closing-stock',
  //   title: 'ClosingS tock',
  //   type: 'item',
  //   url: '/closing-stock',
  //   icon: icons['NoteAddIcon']
  // },
  // {
  //   id: 'create-order',
  //   title: 'Create Order',
  //   type: 'item',
  //   url: '/create-order',
  //   icon: icons['AddBoxIcon']
  // },
  {
    id: 'order-list',
    title: 'Order List',
    type: 'item',
    url: '/order-list',
    icon: icons['ChromeReaderModeOutlinedIcon']
  },
 
  // {
    //   id: 'pages',
    //   title: 'Pages',
    //   caption: 'Prebuild Pages',
    //   type: 'group',
    //   icon: icons['NavigationOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'sample-page',
    //       title: 'Sample Page',
    //       type: 'item',
    //       url: '/sample-page',
    //       icon: icons['ChromeReaderModeOutlinedIcon']
    //     },
    //     {
    //       id: 'auth',
    //       title: 'Authentication',
    //       type: 'collapse',
    //       icon: icons['SecurityOutlinedIcon'],
    //       children: [
    //         {
    //           id: 'login-1',
    //           title: 'Login',
    //           type: 'item',
    //           url: '/application/login',
    //           target: true
    //         },
    //         {
    //           id: 'register',
    //           title: 'Register',
    //           type: 'item',
    //           url: '/application/register',
    //           target: true
    //         }
    //       ]
    //     }
    //   ]
    // },
  
 
];

// eslint-disable-next-line
const caption = localStorage.getItem("role")
export default {
  items: [
    {
      id: 'navigation',
      title: 'Jorder', 
      caption: caption +' '+ 'Dashboard',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: routeuser === 'wholesaler' ? adminRoutes : retailerRoutes
    }
  ]
};
