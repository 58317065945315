// third party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// project import
import customizationReducer from './customizationReducer';
import authenReducer from '../Redux/Slice/authen';

// ==============================|| REDUCER ||============================== //

// Define persist config for authenReducer
const authenPersistConfig = {
  key: 'authen',
  storage,
};

const rootReducer = combineReducers({
  customization: customizationReducer,
  authen: persistReducer(authenPersistConfig, authenReducer),
});

export default rootReducer;
