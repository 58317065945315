import React, { useEffect } from 'react';

// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// project import
import theme from 'themes';
import Routes from 'routes/index';
import NavigationScroll from './NavigationScroll';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router';
// ==============================|| APP ||============================== //

const App = () => {
  const customization = localStorage.getItem("token");
  // const authenticate = useSelector((state) => state.authen.token);
  const authenticateRole = localStorage.getItem("role");
  const Navigate = useNavigate();
  const token = localStorage.getItem("token")

  useEffect(() => {
   
    if (!token) {
      Navigate('/application/login')
    }
  }, [token, Navigate]);
  useEffect(()=> {
    //const aunth = authenticate
    Cookies.set("Role" , authenticateRole);
    //console.log(aunth)
  })
 
  return (
    <>
      {
        <NavigationScroll>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </StyledEngineProvider>
        </NavigationScroll>
      }
    </>
  );
};

export default App;
