import { useRoutes, useNavigate } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const routtoken = localStorage.getItem("token");
  console.log(routtoken, 'route token ');

  const navigate = useNavigate();

  useEffect(() => {
    if (routtoken === null) {
    //  navigate('/application/login');
    }
  }, [routtoken, navigate]); // Added routtoken and navigate to dependency array

  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
