import { createSlice } from '@reduxjs/toolkit';

const TOKEN_EXPIRATION_TIME = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

export const authenSlice = createSlice({
  name: 'authen',
  initialState: {
    token: null,
    role: null,
    tokenExpiration: null,
  },
  reducers: {
    authenticate: (state, action) => {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.tokenExpiration = Date.now() + TOKEN_EXPIRATION_TIME;

      // Automatically log out the user after the token expires
      setTimeout(() => {
        state.token = null;
        state.role = null;
        state.tokenExpiration = null;
      }, TOKEN_EXPIRATION_TIME);
    },
    logout: (state) => {
      state.token = null;
      state.role = null;
      state.tokenExpiration = null;
    },
    checkTokenExpiration: (state) => {
      if (state.tokenExpiration && Date.now() > state.tokenExpiration) {
        state.token = null;
        state.role = null;
        state.tokenExpiration = null;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, logout, checkTokenExpiration } = authenSlice.actions;

export default authenSlice.reducer;
