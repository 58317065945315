import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// assets
import 'assets/scss/style.scss';

// third party
import { configureStore } from '@reduxjs/toolkit';

// project import
import App from 'layout/App';
import reducer from 'store/reducer';
import { store, persistor } from './Redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CircularProgress from '@mui/material/CircularProgress'; // Example loader component

const RootComponent = () => {
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
     
    // Simulate loading for demonstration, remove this in actual app
    setTimeout(() => {
      setLoading(false); // Set loading to false after content is loaded
    }, 1000); // Adjust the timeout based on your actual loading time
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress /> {/* Show loader when loading */}
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename='/'>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

const root = createRoot(document.getElementById('root'));

root.render(<RootComponent />);
