import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';
import AddRetailer from 'views/AddRetailer/AddRetailer';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));

const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const SamplePage = Loadable(lazy(() => import('../views/SamplePage')));
const CreateOrder = Loadable(lazy(() => import('../views/CreateOrder/CreateOrder')));
const Retailer = Loadable(lazy(() => import('../views/Retailer/Retailer')));
const OrderList = Loadable(lazy(() => import('../views/OrderList/OrderList'))); 
const OrderList_new = Loadable(lazy(() => import('../views/OrderList/OrderList_new'))); 
const ProductList = Loadable(lazy(() => import('../views/ProductList/ProductList')));
const CreateProduct = Loadable(lazy(() => import('../views/CreateProduct/CreateProduct')));
const SendNotification = Loadable(lazy(() => import('../views/SendNotification/SendNotification')));
const ClosingStock = Loadable(lazy(() => import('../views/ClosingStock/ClosingStock')));
const Login = Loadable(lazy(() => import('../views/Login/index')));
const Profile = Loadable(lazy(() => import('../views/Profile/Profile')));
const Test = Loadable(lazy(() => import('../views/Dummy/Test')));
// const ForgetPassword = Loadable(lazy(() => import('../views/ForgetPassword/ForgetPassword')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    { path: '/utils/util-typography', element: <UtilsTypography /> },
    { path: '/sample-page', element: <SamplePage /> },
    { path: '/create-order', element: <CreateOrder /> },
    { path: '/Retailer', element: <Retailer /> },
    { path: '/order-list', element: <OrderList /> }, 
    { path: '/order-list_new', element: <OrderList_new /> }, 
    { path: '/product-list', element: <ProductList /> },
    { path: '/create-product', element: <CreateProduct /> },
    { path: '/send-Notification', element: <SendNotification /> },
    { path: '/Profile', element: <Profile /> },
    { path: '/add-retailer', element: <AddRetailer /> },
    { path: '/closing-stock', element: <ClosingStock /> },
    { path: '/test', element: <Test /> },
    // { path: '/forget-password', element: <ForgetPassword /> },
  ]
};

export default MainRoutes;
